// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PlayerProvider } from './contexts/PlayerContext';
import Login from './pages/Login';
import Register from './pages/Register';
import Index from './pages/Index';
import Playlist from './pages/Playlist';
import Search from './pages/Search';
import Player from './components/Player'

const App = () => {
  // Dummy data for demonstration
  const isAuthenticated = true;
  const tracks = [];
  const playlists = [];
  const userFavorites = [];
  const errorMessage = '';

  return (
    <Router>
      <PlayerProvider>
        <Routes>
          <Route path="/account/login" element={<Login isAuthenticated={isAuthenticated} />} />
          <Route path="/account/register" element={<Register isAuthenticated={isAuthenticated} />} />
          <Route 
            path="/" 
            element={
              <Index 
                tracks={tracks}
                playlists={playlists}
                isAuthenticated={isAuthenticated}
                userFavorites={userFavorites}
                errorMessage={errorMessage}
              />
            } 
          />
          <Route 
            path="/playlist/:id" 
            element={
              <Playlist 
                isAuthenticated={isAuthenticated}
                userFavorites={userFavorites}
              />
            } 
          />
          <Route path="/search" element={<Search />} />
        </Routes>
        <Player />
      </PlayerProvider>
    </Router>
  );
};

export default App;