import React, { createContext, useContext, useState, useCallback } from 'react';

const PlayerContext = createContext();

export const PlayerProvider = ({ children }) => {
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentSong, setCurrentSong] = useState(null);
  const [currentCover, setCurrentCover] = useState(null);
  const [error, setError] = useState(null);

  const playAudio = useCallback(() => {
    if (audioPlayer) {
      audioPlayer.play().catch(err => {
        setError(err);
        console.error('Error playing audio:', err);
      });
      setIsPlaying(true);
    }
  }, [audioPlayer]);

  const pauseAudio = useCallback(() => {
    if (audioPlayer) {
      audioPlayer.pause();
      setIsPlaying(false);
    }
  }, [audioPlayer]);

  const setSong = useCallback((songName, cover) => {
    setCurrentSong(songName);
    setCurrentCover(cover);
  }, []);

  return (
    <PlayerContext.Provider value={{ audioPlayer, setAudioPlayer, isPlaying, playAudio, pauseAudio, error, currentSong, setSong, currentCover, setIsPlaying }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => useContext(PlayerContext);