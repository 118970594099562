import React, { useState, useEffect } from 'react';
import { Copy } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const Register = () => {
  const [captchaQuestion, setCaptchaQuestion] = useState('');
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [captchaId, setCaptchaId] = useState('');
  const [seed, setSeed] = useState('');
  const [isSeedVisible, setIsSeedVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Obtiene el captcha al montar el componente
    const fetchCaptcha = async () => {
      try {
        const response = await fetch('https://music-api.buzzster.co.uk/account/register');
        if (response.ok) {
          const result = await response.json();
          setCaptchaQuestion(result.captchaQuestion);
          setCaptchaId(result.captchaId);
          setIsAuthenticated(result.isAuthenticated);
        } else {
          console.error('Error al obtener captcha');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchCaptcha();
  }, []);

  const handleCaptchaChange = (e) => {
    setCaptchaResponse(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://music-api.buzzster.co.uk/account/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ captchaId, captchaResponse }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.seed) {
          setSeed(result.seed);
          setIsSeedVisible(true);
        } else {
          console.error('Respuesta del servidor no contiene seed');
        }
      } else {
        alert('El captcha no es válido');
        console.error('Error al registrar');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(seed)
      .then(() => alert('Frase semilla copiada al portapapeles'))
      .catch(err => alert('Error al copiar la frase semilla: ' + err));
  };
  const registerClick = () => {
    setIsAuthenticated(true);
    navigate('/account/login');
  }

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <main>
        <div className="auth-container">
          <div className="login-container">
            <h2>Regístrate</h2>
            <p>Genera tu frase semilla para guardar favoritos</p>
            {!isSeedVisible ? (
              <form id="registerForm" className="login-form" onSubmit={handleSubmit}>
                <label htmlFor="captchaQuestion">Captcha:</label>
                <div id="captchaQuestion">{captchaQuestion}</div>
                <input
                  type="number"
                  id="captchaResponse"
                  name="captchaResponse"
                  required
                  placeholder="Tu respuesta"
                  value={captchaResponse}
                  onChange={handleCaptchaChange}
                />
                <button type="submit">Generar frase semilla</button>
                <p className="signup-prompt">
                  ¿Ya tienes una cuenta? <Link to="/account/login">Inicia sesión</Link>
                </p>
              </form>
            ) : (
              <div id="seedContainer" className="seed-container">
                <p>Tu frase semilla:</p>
                <div id="seedValue" className="seed-value">{seed}</div>
                <button id="copyButton" className="copy-button" onClick={handleCopyClick}>
                  <Copy />
                </button>
                <button onClick={registerClick}>
                  He copiado mi frase semilla
                </button>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;