import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Loader, Heart } from 'react-feather';
import Header from '../components/Header';

const Index = () => {
  const [tracks, setTracks] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userFavorites, setUserFavorites] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://music-api.buzzster.co.uk');
        const { playlists, userFavorites, isAuthenticated } = response.data;
        
        // If tracks are returned in the response, set them
        // Assuming tracks are included in the response
        setTracks(response.data.tracks || []);
        setPlaylists(playlists);
        setUserFavorites(userFavorites);
        setIsAuthenticated(isAuthenticated);
      } catch (error) {
        setErrorMessage(error.response?.data?.errorMessage || 'An error occurred');
      }
    };

    fetchData();
  }, []); // Empty dependency array means this useEffect runs once when the component mounts

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <main>
        {errorMessage ? (
          <div className="error-wrapper">
            <div className="error-message">
              <i className="fas fa-exclamation-triangle"></i>
              <h1>{errorMessage}</h1>
            </div>
          </div>
        ) : (
          <>
            {tracks && tracks.length > 0 ? (
              <>
                <h2>Your Tracks</h2>
                <ul id="trackList">
                  {tracks.map(track => (
                    <li key={track.spotifyId}>
                      <img src={track.albumCover} alt={track.title} className="cover" />
                      <div className="track-info">
                        <span className="track-title">{track.title}</span>
                        <span className="track-artist">{track.artist}</span>
                      </div>
                      <button className="play-button" data-song-id={track.spotifyId}>Reproducir</button>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <h2>Playlists Destacadas</h2>
                <div id="playlistGrid">
                  {playlists && playlists.length > 0 ? (
                    playlists.map(playlist => (
                      <div className="playlist-item" key={playlist.id}>
                        <Link to={`/playlist/${playlist.id}`}>
                          <img src={playlist.images[0].url} alt={playlist.name} className="cover" />
                          <span>{playlist.name}</span>
                        </Link>
                        {isAuthenticated && (
                          <button 
                            className="like-button" 
                            data-id={playlist.id}
                            data-is-favorite={userFavorites.includes(playlist.id)}
                            data-title={playlist.name}
                            data-album-cover={playlist.images[0].url}
                            data-type="playlist">
                            <Heart style={{ color: userFavorites.includes(playlist.id) ? 'green' : 'grey' }}/>
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="error-wrapper">
                      <div className="error-message">
                        <h1><Loader className="spinner" /></h1>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </main>
    </>
  );
};

export default Index;