import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Heart } from 'react-feather';
import Header from '../components/Header';
import { usePlayer } from '../contexts/PlayerContext';

const Playlist = ({ userFavorites }) => {
  const { id } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [playlist, setPlaylist] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const { setSong } = usePlayer();

  const handleClick = (songName, albumCover) => {
    setSong(songName, albumCover);
  };

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        const response = await fetch(`https://music-api.buzzster.co.uk/playlist/${id}`);
        if (response.ok) {
          const data = await response.json();
          setPlaylist(data);
        } else {
          const result = await response.json();
          setErrorMessage(result.errorMessage || 'Error al cargar la lista de reproducción');
        }
      } catch (error) {
        setErrorMessage('Error al cargar la lista de reproducción: ' + error.message);
      }
    };

    fetchPlaylist();
  }, [id]);

  if (errorMessage) {
    return (
      <div className="error-wrapper">
        <div className="error-message">
          <h1>{errorMessage}</h1>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <main>
        <ul id="songList">
          {playlist === null ? (
            <div className="error-wrapper">
              <div className="error-message">
                <h1><Loader className="spinner" /></h1>
              </div>
            </div>
          ) : (
            playlist.tracks.map((track) => (
              <li key={track.title}>
                <button
                  onClick={() => handleClick(`${track.title} - ${track.artist}`, track.albumCover)}
                  style={{ border: 'none', background: 'none', cursor: 'pointer', padding: '0' }}
                >
                  <img src={track.albumCover} alt={track.title} className="cover" />
                  <span>{track.title} - {track.artist}</span>
                </button>
                {isAuthenticated && (
                  <button
                    className="like-button"
                    style={{ color: userFavorites.includes(track.title) ? 'green' : 'grey' }}
                  >
                    <Heart />
                  </button>
                )}
              </li>
            ))
          )}
        </ul>
      </main>
    </>
  );
};

export default Playlist;