// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { PlayerProvider } from './contexts/PlayerContext';
import './styles.css';

ReactDOM.render(
  <PlayerProvider>
    <App />
  </PlayerProvider>,
  document.getElementById('root')
);