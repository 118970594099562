import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ isAuthenticated }) => {
  useEffect(() => {
    const menuIcon = document.getElementById('menu-icon');
    const header = document.querySelector('header');

    const handleClick = () => {
      header.classList.toggle('menu-active');
    };

    menuIcon.addEventListener('click', handleClick);

    // Cleanup function to remove the event listener
    return () => {
      menuIcon.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <header>
      <div className="menu-icon" id="menu-icon">
        &#9776;
      </div>
      
      <Link to="/">
        <svg xmlns="http://www.w3.org/2000/svg" alt="Buzzster" width="200" viewBox="0 0 19200 3120">
        <g id="lvLPg6sNcxvXEVymJVVChZ" fill="rgb(255,255,255)">
              <g>
                 <path id="p2EpMHE4r" d="M3840 2725 c-74 -25 -140 -53 -147 -62 -7 -9 -31 -24 -53 -34 -80 -36 -300 -242 -300 -281 0 -6 -10 -25 -23 -42 -47 -63 -87 -181 -97 -286 -5 -54 -13 -432 -16 -839 l-7 -741 326 0 327 0 0 748 0 747 45 52 c49 56 52 63 30 63 -8 0 -15 5 -15 10 0 6 19 10 42 10 29 0 49 7 66 23 21 20 34 22 125 22 90 0 106 -3 134 -22 17 -13 36 -23 43 -23 6 0 32 -21 58 -47 75 -75 74 -61 77 -860 2 -381 6 -701 9 -711 5 -16 28 -17 318 -14 172 2 315 6 317 8 3 2 0 370 -6 817 -11 783 -12 814 -32 871 -58 165 -269 466 -327 466 -8 0 -30 11 -48 25 -18 14 -39 25 -47 25 -8 0 -25 9 -39 20 -14 11 -31 20 -37 20 -7 0 -32 8 -55 19 -88 39 -178 53 -358 58 l-175 4 -135 -46z"></path>
                 <path id="pQIRxa9wG" d="M7430 2756 c0 -8 -8 -17 -17 -21 -10 -5 -441 -11 -958 -16 -517 -4 -946 -8 -952 -8 -30 -2 2 -95 46 -135 12 -11 21 -27 21 -36 0 -9 13 -34 30 -56 16 -21 30 -42 30 -46 0 -14 81 -133 96 -141 9 -5 13 -16 9 -28 -4 -13 5 -37 28 -72 19 -29 40 -63 47 -77 7 -14 21 -31 32 -38 10 -7 18 -24 18 -37 0 -13 7 -30 15 -39 8 -8 15 -21 15 -29 0 -8 8 -25 19 -38 10 -13 32 -52 50 -86 17 -34 51 -93 75 -130 24 -37 69 -119 101 -182 32 -62 73 -133 91 -157 19 -24 34 -53 34 -63 0 -11 7 -26 15 -35 8 -8 15 -21 15 -28 0 -7 14 -32 30 -56 43 -64 66 -130 51 -145 -9 -9 -119 -11 -452 -9 l-439 2 0 -305 0 -305 923 -1 c916 0 1067 -4 1075 -27 7 -23 22 -12 22 17 0 16 -17 58 -38 92 -22 35 -100 174 -175 309 -74 135 -257 465 -406 734 -149 269 -271 491 -271 493 0 1 186 0 414 -4 l414 -6 6 44 c3 24 8 187 12 362 6 287 5 317 -10 317 -9 0 -16 -6 -16 -14z"></path>
                 <path id="p10hOMup68" d="M10660 2763 c-106 -16 -241 -48 -272 -64 -20 -10 -47 -19 -58 -19 -11 0 -38 -9 -60 -20 -22 -11 -47 -20 -57 -20 -9 0 -51 -15 -93 -34 -41 -19 -83 -37 -92 -40 -10 -3 -18 -13 -18 -21 0 -20 100 -185 112 -185 4 0 8 -7 8 -15 0 -9 16 -44 36 -78 141 -243 146 -249 157 -207 3 12 13 20 26 20 11 0 25 9 31 20 7 14 21 20 43 20 18 0 46 9 62 20 17 11 40 20 53 20 12 0 30 9 39 19 9 11 21 17 28 15 6 -3 38 5 71 18 55 22 73 23 294 23 228 0 236 -1 267 -22 17 -13 39 -23 48 -23 9 0 14 -4 10 -9 -3 -5 2 -25 11 -44 23 -48 12 -98 -33 -148 -40 -44 -81 -69 -113 -69 -11 0 -31 -7 -44 -15 -13 -9 -70 -25 -127 -35 -211 -41 -345 -73 -374 -89 -17 -10 -48 -21 -70 -24 -42 -7 -195 -67 -217 -84 -7 -6 -29 -18 -48 -28 -43 -21 -150 -124 -150 -145 0 -8 -9 -26 -20 -40 -11 -14 -20 -34 -20 -45 0 -12 -4 -25 -10 -31 -22 -22 -43 -143 -44 -254 -1 -94 3 -129 22 -190 28 -89 81 -210 93 -210 5 0 15 -15 22 -33 17 -40 119 -135 155 -144 15 -3 33 -15 40 -25 17 -23 115 -73 162 -82 19 -4 60 -16 90 -26 73 -24 278 -50 399 -50 113 0 502 62 539 86 13 9 48 22 78 30 96 25 234 102 234 131 0 8 -18 38 -41 66 -22 28 -43 64 -46 80 -3 16 -12 31 -19 34 -8 3 -14 14 -14 25 0 11 -11 34 -23 51 -13 18 -41 64 -62 102 -39 72 -72 115 -88 115 -5 0 -22 -11 -39 -25 -16 -14 -38 -25 -49 -25 -11 0 -37 -9 -59 -20 -22 -11 -46 -20 -55 -20 -9 0 -28 -9 -43 -20 -66 -48 -427 -88 -546 -61 -82 20 -141 115 -113 184 15 35 66 83 104 98 13 5 159 44 325 88 397 105 466 130 598 216 93 62 135 111 189 224 l46 96 0 190 c0 209 -12 290 -52 338 -13 16 -23 39 -23 51 0 13 -10 36 -22 52 -39 52 -142 154 -156 154 -8 0 -27 9 -43 21 -16 11 -33 18 -37 15 -5 -3 -16 4 -25 15 -9 10 -26 19 -37 19 -11 0 -34 9 -52 20 -18 11 -48 20 -66 20 -18 0 -46 7 -62 15 -17 9 -74 20 -128 25 -93 9 -520 12 -572 3z"></path>
                 <path id="p8CaLSzmD" d="M7600 2734 c0 -15 15 -52 34 -83 18 -31 53 -92 76 -136 24 -44 55 -98 70 -120 15 -22 148 -256 295 -520 147 -264 271 -484 275 -490 24 -30 150 -278 145 -284 -4 -3 -200 -7 -436 -9 l-429 -4 0 -116 c0 -64 -3 -222 -7 -350 -5 -205 -4 -232 9 -232 9 0 18 8 20 18 5 16 20 20 104 25 55 4 482 7 949 8 468 0 867 3 888 7 l39 7 -26 24 c-14 14 -26 35 -26 48 0 13 -7 26 -15 29 -8 4 -15 14 -15 23 0 9 -9 27 -20 41 -11 14 -20 31 -20 39 0 8 -13 35 -29 60 -15 25 -44 75 -64 111 -19 36 -44 73 -55 83 -12 11 -17 26 -15 42 4 16 -1 27 -16 37 -12 7 -21 20 -21 28 0 8 -9 26 -20 40 -11 14 -20 31 -20 38 0 15 -62 118 -97 162 -13 15 -23 37 -23 47 0 11 -9 28 -20 38 -11 10 -20 26 -20 35 0 9 -9 25 -20 35 -11 10 -20 29 -20 42 0 13 -11 39 -23 56 -13 18 -39 59 -57 92 -18 33 -44 74 -57 92 -12 17 -23 38 -23 45 0 7 -9 24 -20 38 -11 14 -20 31 -20 39 0 8 -11 29 -25 47 -14 18 -25 36 -25 41 0 4 -16 33 -35 64 -20 33 -38 77 -41 103 l-7 46 425 0 425 0 6 182 c4 99 7 234 7 300 l0 118 -917 0 c-877 1 -1053 5 -1061 29 -7 21 -22 11 -22 -15z"></path>
                 <path id="pbzbJbdxo" d="M560 1371 l0 -1351 248 0 c453 1 1266 19 1341 30 44 6 93 21 119 35 25 14 50 25 58 25 7 0 19 8 26 18 7 10 26 22 41 26 33 8 196 161 217 203 8 15 24 39 37 53 35 40 93 263 93 363 0 139 -60 321 -150 456 -32 47 -62 98 -65 113 -6 24 -3 29 21 38 42 16 80 56 136 140 64 97 122 220 138 291 8 38 11 104 7 200 -5 151 -21 226 -57 272 -11 14 -20 35 -20 46 0 12 -18 41 -40 64 -21 24 -43 55 -49 69 -6 16 -20 27 -38 31 -17 3 -46 26 -76 61 -27 31 -55 56 -63 56 -9 0 -22 7 -30 15 -9 8 -26 15 -40 15 -14 0 -27 7 -30 15 -3 8 -22 19 -42 25 -60 17 -434 28 -1129 36 l-653 7 0 -1352z m1327 790 c42 -8 59 -19 102 -63 82 -85 112 -169 97 -272 -8 -52 -73 -147 -131 -192 -48 -37 -91 -42 -422 -50 l-273 -7 0 303 0 303 288 -6 c158 -4 311 -11 339 -16z m83 -1126 l85 -85 0 -114 c0 -94 -3 -117 -17 -133 -10 -11 -18 -27 -18 -36 0 -9 -6 -17 -13 -17 -7 0 -43 -22 -80 -49 -93 -68 -127 -75 -399 -75 -205 -1 -229 1 -245 17 -16 16 -18 44 -21 297 l-3 280 313 0 313 0 85 -85z"></path>
                 <path id="p1CuOo8iBR" d="M12776 1860 l7 -860 -336 0 -337 0 0 -280 0 -280 975 0 975 0 0 280 0 280 -330 0 -330 0 0 860 0 860 -316 0 -315 0 7 -860z"></path>
                 <path id="p17WH6TYXz" d="M14435 2710 l-120 -5 -6 -160 c-4 -88 -7 -597 -8 -1132 l-1 -971 898 -4 c493 -2 899 -2 902 0 3 2 7 123 8 269 l4 265 -605 -8 -606 -7 -8 39 c-7 37 -9 282 -1 289 2 2 257 9 568 15 311 6 566 12 567 13 1 2 5 120 9 265 l7 262 -214 0 c-239 0 -932 11 -937 15 -7 6 -6 291 1 303 6 9 139 12 608 12 l599 0 0 118 c0 64 3 188 7 275 l6 157 -779 -2 c-428 -1 -833 -4 -899 -8z"></path>
                 <path id="pOoZbkWxW" d="M16460 1580 l0 -1140 518 0 c716 0 947 16 966 65 3 9 18 15 35 15 19 0 34 7 40 19 6 10 21 21 33 24 31 8 192 170 213 214 9 19 23 37 31 40 8 3 14 15 14 28 0 12 8 40 17 61 14 32 17 72 18 219 0 168 -2 183 -22 222 -13 23 -23 49 -23 58 0 9 -7 18 -15 21 -8 4 -15 15 -15 27 0 11 -11 34 -24 51 -13 17 -26 40 -30 51 -9 31 -76 95 -98 95 -18 0 -20 5 -16 45 6 46 124 300 212 455 23 41 50 96 60 121 9 26 26 57 36 70 11 12 20 30 20 39 0 9 9 25 20 35 12 11 18 25 15 36 -4 12 1 22 15 29 12 6 20 21 20 35 0 13 12 35 26 49 18 16 25 31 22 44 -4 13 4 28 23 47 35 32 37 51 7 59 -13 3 -165 6 -338 6 l-316 0 -54 -112 c-30 -61 -72 -138 -92 -170 -21 -32 -38 -70 -38 -83 0 -14 -8 -29 -20 -35 -13 -7 -20 -21 -20 -40 0 -19 -7 -33 -20 -40 -13 -7 -20 -21 -20 -40 0 -18 -7 -33 -19 -39 -10 -6 -21 -24 -24 -40 -3 -16 -23 -47 -44 -69 -47 -50 -77 -55 -281 -50 -129 2 -154 6 -169 21 -17 16 -18 43 -16 357 l2 340 -325 0 -324 0 0 -1140z m983 -169 c91 -7 113 -13 147 -35 22 -14 44 -26 50 -26 5 0 20 -19 32 -42 17 -34 23 -65 26 -145 4 -98 3 -102 -19 -114 -13 -7 -45 -29 -72 -50 -71 -57 -103 -62 -313 -54 l-182 7 -7 172 c-4 94 -3 196 1 226 8 46 13 55 34 61 35 10 175 10 303 0z"></path>
              </g>
           </g>
           <g id="l46t01kaSqWy6WV9JqHB1Be" fill="#121212">
              <g>
                 <path id="pPvg4aOqd" d="M7423 2627 c-1 -254 -5 -482 -8 -506 l-7 -44 -429 7 c-236 4 -429 4 -429 0 0 -6 130 -241 713 -1294 72 -129 149 -266 172 -305 23 -38 54 -93 69 -122 14 -28 31 -55 37 -59 6 -4 28 -43 49 -86 l37 -80 7 69 c10 99 25 524 26 701 l0 152 383 1 c210 1 413 4 450 8 l68 6 -67 130 c-37 72 -90 171 -119 220 -29 50 -162 286 -295 525 -134 239 -255 453 -270 475 -15 22 -46 76 -70 120 -23 44 -64 116 -90 160 -44 75 -140 250 -185 339 -11 22 -24 41 -30 43 -7 2 -11 -157 -12 -460z"></path>
              </g>
           </g>
        </svg>
      </Link>
      <form action="/search" method="get">
        <input type="text" name="q" placeholder="Busca algo..." required />
        <button type="submit">Buscar</button>
      </form>
      {isAuthenticated ? (
        <Link to="/favorites" className="auth-link">Favoritos</Link>
      ) : (
        <Link to="/account/login" className="auth-link">Acceder</Link>
      )}
    </header>
  );
};

export default Header;