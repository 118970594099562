import React, { useEffect, useState, useRef } from 'react';
import { Play, Pause, Loader } from 'react-feather';
import { usePlayer } from '../contexts/PlayerContext';

const Player = () => {
  const { audioPlayer, setAudioPlayer, isPlaying, playAudio, pauseAudio, currentSong, currentCover, setIsPlaying } = usePlayer();
  const [loading, setLoading] = useState(false);
  const progressBarRef = useRef(null);

  useEffect(() => {
    if (currentSong) {
      const audioUrl = `https://music-api.buzzster.co.uk/stream/${encodeURIComponent(currentSong)}`;
      setLoading(true);

      if (audioPlayer) {
        // Set the new source and load the audio
        audioPlayer.src = audioUrl;
        audioPlayer.load();
        audioPlayer.addEventListener('canplaythrough', () => {
          setLoading(false);
          if (isPlaying) {
            audioPlayer.play().catch(err => console.error('Error playing audio:', err));
          }
        });

        audioPlayer.addEventListener('error', () => {
          setLoading(false);
          console.error('Error loading audio');
        });
      } else {
        const newAudio = new Audio(audioUrl);
        setAudioPlayer(newAudio);
        newAudio.addEventListener('canplaythrough', () => {
          setLoading(false);
          if (isPlaying) {
            newAudio.play().catch(err => console.error('Error playing audio:', err));
          }
        });

        newAudio.addEventListener('error', () => {
          setLoading(false);
          console.error('Error loading audio');
        });
      }

      // Clean up
      return () => {
        if (audioPlayer) {
          audioPlayer.removeEventListener('canplaythrough', () => {});
          audioPlayer.removeEventListener('error', () => {});
          audioPlayer.pause();
          audioPlayer.src = '';
        }
      };
    }
  }, [currentSong, isPlaying, audioPlayer, setAudioPlayer]);

  useEffect(() => {
    if (audioPlayer) {
      const updateProgress = () => {
        if (progressBarRef.current && audioPlayer.duration) {
          progressBarRef.current.value = (audioPlayer.currentTime / audioPlayer.duration) * 100;
        }
      };

      const handleEnded = () => {
        setIsPlaying(false);
      };

      audioPlayer.addEventListener('timeupdate', updateProgress);
      audioPlayer.addEventListener('ended', handleEnded);

      // Clean up
      return () => {
        if (audioPlayer) {
          audioPlayer.removeEventListener('timeupdate', updateProgress);
          audioPlayer.removeEventListener('ended', handleEnded);
          audioPlayer.pause();
          audioPlayer.src = '';
        }
      };
    }
  }, [audioPlayer, isPlaying, setIsPlaying]);

  const handlePlayPause = () => {
    if (audioPlayer) {
      if (isPlaying) {
        audioPlayer.pause();
        pauseAudio();
        setIsPlaying(false);
      } else {
        audioPlayer.play().catch(err => console.error('Error playing audio:', err));
        playAudio();
        setIsPlaying(true);
      }
    }
  };

  return (
    <footer>
      <div id="playerContainer">
        <img id="currentCover" src={currentCover || "/img/cover.svg"} alt="Cover" className="cover" />
        <div id="player-controls">
          <button id="playPauseButton" onClick={handlePlayPause} disabled={loading}>
            {loading ? <Loader className="spinner" /> : isPlaying ? <Pause /> : <Play />}
          </button>
          <input
            type="range"
            id="progressBar"
            min="0"
            max="100"
            defaultValue="0"
            ref={progressBarRef}
            disabled
          />
        </div>
      </div>
    </footer>
  );
};

export default Player;