import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Loader, Heart } from 'react-feather';
import Header from '../components/Header';
import { usePlayer } from '../contexts/PlayerContext';

const Search = () => {
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState({
        tracks: [],
        albums: [],
        playlists: [],
        userFavorites: [],
        isAuthenticated: false,
        userId: null
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const query = searchParams.get('q') || '';

    const { setSong } = usePlayer();

    const handleClick = (songName, albumCover) => {
        setSong(songName, albumCover);
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://music-api.buzzster.co.uk/search', { params: { q: query } });
                setResults(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        if (query) {
            fetchData();
        }
    }, [query]);

    const { tracks, albums, playlists, userFavorites, isAuthenticated } = results;

    if (loading) return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <main>
                <div className="error-wrapper">
                    <div className="error-message">
                    <Loader className="spinner" />
                    </div>
                </div>
            </main>
        </>
    );
    if (error) return (
        <>
            <Header isAuthenticated={isAuthenticated} />
                <main>
                <div className="error-wrapper">
                    <div className="error-message">
                        <i className="fas fa-exclamation-triangle"></i>
                        <h1>{error}</h1>
                    </div>
                </div>
            </main>
        </>
    );

    return (
        <>
            <Header isAuthenticated={isAuthenticated} />
            <main>
                <h1>Resultados de búsqueda para "{query}"</h1>

                <section>
                    <h2>Listas de Reproducción</h2>
                    <div id="playlistGrid">
                        {playlists.length > 0 ? (
                            playlists.map(playlist => (
                                <div className="playlist-item" key={playlist.id}>
                                    <Link to={`/playlist/${playlist.id}`} className="playlist-item">
                                        <img src={playlist.images[0].url} alt={playlist.name} className="cover" />
                                        <span>{playlist.name}</span>
                                    </Link>
                                    {isAuthenticated && (
                                        <button 
                                            className="like-button" 
                                            data-id={playlist.id}
                                            data-title={playlist.name}
                                            data-album-cover={playlist.images[0].url}
                                            data-type="playlist"
                                            data-is-favorite={userFavorites.includes(playlist.id)}
                                        >
                                            <i 
                                                className="fa fa-heart" 
                                                style={{ color: userFavorites.includes(playlist.id) ? 'green' : 'grey' }}
                                            ></i>
                                        </button>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>No se encontraron listas de reproducción.</p>
                        )}
                    </div>
                </section>

                <section>
                    <h2>Canciones</h2>
                    <ul id="songList">
                        {tracks.length > 0 ? (
                            tracks.map(track => (
                                <li key={track.spotifyId}>
                                    <button
                                    onClick={() => handleClick(`${track.title} - ${track.artist}`, track.albumCover)}
                                    style={{ border: 'none', background: 'none', cursor: 'pointer', padding: '0' }}
                                    >
                                        <img src={track.albumCover} alt={track.title} className="cover" />
                                        <span>{track.title} - {track.artist}</span>
                                    </button>
                                    {isAuthenticated && (
                                        <button 
                                            className="like-button" 
                                            data-id={track.spotifyId}
                                            data-title={track.title}
                                            data-artist={track.artist}
                                            data-album-cover={track.albumCover}
                                            data-type="track"
                                            data-is-favorite={userFavorites.includes(track.spotifyId)}
                                        >
                                            <i 
                                                className="fa fa-heart" 
                                                style={{ color: userFavorites.includes(track.spotifyId) ? 'green' : 'grey' }}
                                            ></i>
                                        </button>
                                    )}
                                </li>
                            ))
                        ) : (
                            <p>No se encontraron canciones.</p>
                        )}
                    </ul>
                </section>
            </main>
        </>
    );
};

export default Search;