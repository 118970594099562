import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const Login = () => {
  const [seed, setSeed] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSeed(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://music-api.buzzster.co.uk/account/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({ seed }),
      });
      if (response.ok) {
        setIsAuthenticated(true);
        navigate('/');
      } else {
        alert('La frase semilla no existe');
        console.error('Invalid credentials');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <Header isAuthenticated={isAuthenticated} />
      <main>
        <div className="auth-container">
          <div className="login-container">
            <h2>Inicia sesión</h2>
            <p>Para guardar favoritos</p>
            <form onSubmit={handleSubmit} className="login-form">
              <input
                type="text"
                id="seed"
                name="seed"
                placeholder="Frase Semilla"
                required
                value={seed}
                onChange={handleChange}
              />
              <button type="submit">Iniciar sesión</button>
              <p className="signup-prompt">
                ¿No tienes una cuenta? <Link to="/account/register">Genera tu frase semilla</Link>
              </p>
            </form>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
